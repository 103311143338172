jarallax(document.querySelectorAll(".jarallax"));

var scrollPosition = window.scrollY;
var body = document.body;

window.addEventListener('scroll', function () {
    scrollPosition = window.scrollY;
    if (scrollPosition >= 200) {
        body.classList.add('scrolled');
    } else {
        body.classList.remove('scrolled');
    }
});
window.addEventListener('ready', function () {
    scrollPosition = window.scrollY;
    if (scrollPosition >= 200) {
        body.classList.add('scrolled');
    } else {
        body.classList.remove('scrolled');
    }
});
document.querySelectorAll('a[href^="#"]').forEach(a => {
    a.addEventListener('click', function (e) {
        e.preventDefault();
        var href = this.getAttribute("href");
        var elem = document.querySelector(href) || document.querySelector("a[name=" + href.substring(1, href.length) + "]");
        //gets Element with an id of the link's href
        //or an anchor tag with a name attribute of the href of the link without the #
        window.scroll({
            top: elem.offsetTop - 70,
            left: 0,
            behavior: 'smooth'
        });
        //if you want to add the hash to window.location.hash
        //you will need to use setTimeout to prevent losing the smooth scrolling behavior
        //the following code will work for that purpose
        setTimeout(function () {
            window.location.hash = this.hash;
        }, 2000);
    });
});

document.querySelectorAll('.level_1 > .submenu > .submenu').forEach(el => {
    el.addEventListener('click', function (e) {
        e.preventDefault();
        var elem = this;
        elem.classList.toggle('active')
    })
});

(function () {
    var e = {
        skipClass: "skip", init: function () {
            var t = navigator.userAgent.toLowerCase(), n = t.indexOf("webkit") > -1, r = t.indexOf("msie") > -1;
            if (n || r) {
                var i = document.body, s = e.click;
                i.addEventListener ? i.addEventListener("click", s, !1) : i.attachEvent && i.attachEvent("onclick", s)
            }
        }, trim: function (e) {
            return e.replace(/^\s\s*/, "").replace(/\s\s*$/, "")
        }, click: function (t) {
            t = t || window.event;
            var n = t.target || t.srcElement, r = n.className.split(" ");
            for (var i = 0; i < r.length; i++) {
                var s = e.trim(r[i]);
                if (s === e.skipClass) {
                    e.focus(n);
                    break
                }
            }
        }, focus: function (e) {
            if (e.href) {
                var t = e.href, n = t.substr(t.indexOf("#") + 1), r = document.getElementById(n);
                if (r) {
                    r.setAttribute("tabindex", "-1");
                    r.focus()
                }
            }
        }
    };
    e.init()
})();

var scrollPosition = window.scrollY;
var body = document.body;

window.addEventListener('scroll', function () {
    scrollPosition = window.scrollY;
    if (scrollPosition >= 200) {
        body.classList.add('scrolled');
    } else {
        body.classList.remove('scrolled');
    }
});
window.addEventListener('ready', function () {
    scrollPosition = window.scrollY;
    if (scrollPosition >= 200) {
        body.classList.add('scrolled');
    } else {
        body.classList.remove('scrolled');
    }
});
document.querySelectorAll('a[href^="#"]').forEach(a => {
    a.addEventListener('click', function (e) {
        e.preventDefault();
        var href = this.getAttribute("href");
        var elem = document.querySelector(href) || document.querySelector("a[name=" + href.substring(1, href.length) + "]");
        //gets Element with an id of the link's href
        //or an anchor tag with a name attribute of the href of the link without the #
        window.scroll({
            top: elem.offsetTop - 70,
            left: 0,
            behavior: 'smooth'
        });
        //if you want to add the hash to window.location.hash
        //you will need to use setTimeout to prevent losing the smooth scrolling behavior
        //the following code will work for that purpose
        setTimeout(function () {
            window.location.hash = this.hash;
        }, 2000);
    });
});

document.querySelectorAll('.level_1 > .submenu > .submenu').forEach(el => {
    el.addEventListener('click', function (e) {
        e.preventDefault();
        var elem = this;
        elem.classList.toggle('active')
    })
});

(function () {
    var e = {
        skipClass: "skip", init: function () {
            var t = navigator.userAgent.toLowerCase(), n = t.indexOf("webkit") > -1, r = t.indexOf("msie") > -1;
            if (n || r) {
                var i = document.body, s = e.click;
                i.addEventListener ? i.addEventListener("click", s, !1) : i.attachEvent && i.attachEvent("onclick", s)
            }
        }, trim: function (e) {
            return e.replace(/^\s\s*/, "").replace(/\s\s*$/, "")
        }, click: function (t) {
            t = t || window.event;
            var n = t.target || t.srcElement, r = n.className.split(" ");
            for (var i = 0; i < r.length; i++) {
                var s = e.trim(r[i]);
                if (s === e.skipClass) {
                    e.focus(n);
                    break
                }
            }
        }, focus: function (e) {
            if (e.href) {
                var t = e.href, n = t.substr(t.indexOf("#") + 1), r = document.getElementById(n);
                if (r) {
                    r.setAttribute("tabindex", "-1");
                    r.focus()
                }
            }
        }
    };
    e.init()
})();